/*Package Imports*/
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
/*App Imports*/
import { organizationActions } from "../../data/redux/store/organization";
import { serviceActions } from "../../data/redux/store/service";
import { memberActions } from "../../data/redux/store/member";
import { locationsActions } from "../../data/redux/store/locations";
import { themeActions } from "../../data/redux/store/theme";
import {
  useGetAllMembersQuery,
  useGetMembersByOrganizationIDQuery,
  useGetAllLocationsQuery,
  useGetAllLocationsByOrganizationIDQuery,
  useGetAllOrganizationsQuery,
  useGetAlertsByMemberIDQuery,
  useGetMemberByMemberEmailQuery,
  useGetAutoReportsByMemberIDQuery,
} from "../../data/redux/store/backofficeApi";
import DashboardPoller from "./NewLocationPoller";

const DataPoller = () => {
  const dispatch = useDispatch();
  const currentMemberEmail = useSelector((state) => state.member.email);
  const currentMember = useSelector((state) => state.member);
  const currentMemberID = useSelector((state) => state.member.memberID);
  const currentMemberRole = useSelector((state) => state.member.role);
  const currentMemberOrganizationID = useSelector((state) => state.member.organizationID);
  const currentOrganizationMembers = useSelector((state) => state.organization.members);
  const currentOrganizationLocations = useSelector((state) => state.organization.locations);
  const currentServiceLocations = useSelector((state) => state.service.allLocationsList);
  const currentLocations = useSelector((state) => state.locations.locations);
  const changedLocationData = useSelector((state) => state.organization.changedLocationData);
  const [newLocationID, setNewLocationID] = useState(0);
  //Members
  //Poll every few seconds, if member is NOT AdminS, use GetAllMembers endpoint; if member is AdminS use getMembersByOrgID to get the specifc org you're trying to view as an admin for
  const { data: getCurrentMember } = useGetMemberByMemberEmailQuery(currentMemberEmail, {pollingInterval: 5000});
  const { data: members } = useGetMembersByOrganizationIDQuery(currentMemberOrganizationID, {pollingInterval: 5000});
  const { data: allMembersList } = useGetAllMembersQuery({}, {pollingInterval: 5000});
  const { data: locations } = useGetAllLocationsByOrganizationIDQuery(currentMemberOrganizationID, {pollingInterval: 5000});
  const { data: allLocationsList } = useGetAllLocationsQuery({}, {pollingInterval: 5000});
  const { data: allOrganizations } = useGetAllOrganizationsQuery();
  const { data: memberNotifications } = useGetAlertsByMemberIDQuery(currentMemberID);
  const { data: memberAutoReports } = useGetAutoReportsByMemberIDQuery(currentMemberID);

  useEffect(() => {
    if (currentMemberRole === "AdminS" && members !== undefined) {
      //if there was an add or remove, or changed member data is true, set members to new list
      if (
        currentOrganizationMembers === undefined ||
        !members.every(function (element) {
          return currentOrganizationMembers.includes(element);
        })
      ) {
        let totalCountActive = 0;

        members.forEach((element) => {
          if (element.enabled === "Enabled") {
            totalCountActive++;
          }
        });
        dispatch(organizationActions.setValue({ field: "members", value: members }));
        dispatch(organizationActions.setValue({ field: "totalInUse", value: totalCountActive }));
      }

      if (allOrganizations !== undefined) {
        const currentAdminSOrg = allOrganizations.find((element) => element.OrganizationID === currentMemberOrganizationID);
        dispatch(organizationActions.setValue({ field: "totalLicenses", value: currentAdminSOrg.TotalLicenses }));
      }
    } else if (currentMemberRole !== "AdminS" && allMembersList !== undefined) {
      if (
        currentOrganizationMembers === undefined ||
        !allMembersList.every(function (element) {
          return currentOrganizationMembers.includes(element);
        })
      ) {
        let totalCountActive = 0;

        allMembersList.forEach((element) => {
          if (element.enabled === "Enabled") {
            totalCountActive++;
          }
        });
        dispatch(organizationActions.setValue({ field: "members", value: allMembersList }));
        dispatch(organizationActions.setValue({ field: "totalInUse", value: totalCountActive }));
      }
    }

    //Locations
    if (currentMemberRole === "AdminS" && locations !== undefined) {
      if (locations !== currentOrganizationLocations) {
        dispatch(organizationActions.setValue({ field: "locations", value: locations }));
      }
    } else if (currentMemberRole !== "AdminS" && allLocationsList !== undefined) {
      if (currentMemberRole === "Service" || currentMemberRole === "Billing") {
        if (allLocationsList.length !== currentServiceLocations.length) {
          dispatch(serviceActions.setValue({ field: "allLocationsList", value: allLocationsList }));
        }
      }
      if (allLocationsList.length !== currentLocations.length) {
        allLocationsList.forEach((element) => {
          if (currentLocations.find((e) => e.LocationID === element.LocationID) === undefined) {
            const newLocation = {
              LocationID: element.LocationID,
              LocationName: element.LocationName,
              MIMOErrorsCount: -1,
              ThresholdAlertsCount: -1,
              OpenTillAlertsCount: -1,
              MIMOErrors: [],
              ExposureAlerts: [],
              ThresholdAlerts: [],
              OpenTillAlerts: [],
              DBGrandTotals: [
                {
                  data: [],
                  labels: [],
                },
              ],
              //TODO might not need terminals, might delete later
              DBCassetteHopperCount: [
                {
                  data: [],
                  labels: [],
                  terminals:[],
                },
              ],
              DBDailyTotals: [
                {
                  deposit: [0, 0, 0, 0, 0, 0, 0, 0, 0],
                  dispense: [0, 0, 0, 0, 0, 0, 0, 0, 0],
                  difference: [0, 0, 0, 0, 0, 0, 0, 0, 0],
                  labels: ["1¢", "5¢", "10¢", "25¢", "$1", "$5", "$10", "$20", "$100"],
                },
              ],
              DBAverageCount: [
                {
                  data: [],
                  labels: [],
                  // deposit: [0, 0, 0, 0, 0, 0, 0, 0, 0],
                  // dispense: [0, 0, 0, 0, 0, 0, 0, 0, 0],
                  // change: [0, 0, 0, 0, 0, 0, 0, 0, 0],
                  // vault: [0, 0, 0, 0, 0, 0, 0, 0, 0],
                  
                },
              ],
              DBVaultTransactions: [
                {
                  data: [],
                },
              ],
            };
            setNewLocationID(newLocation.LocationID);
            dispatch(locationsActions.setValue({ field: "addLocation", value: newLocation }));
          }
        });
      }
      if (allLocationsList !== currentOrganizationLocations) {
        dispatch(organizationActions.setValue({ field: "locations", value: allLocationsList }));
        dispatch(
          organizationActions.setValue({
            field: "changedLocationData",
            value: false,
          })
        );
      }
    }

    if (allOrganizations !== undefined) {
      dispatch(serviceActions.setValue({ field: "allOrganizationsList", value: allOrganizations }));
    }

    if (memberNotifications !== undefined) {
      dispatch(memberActions.setValue({ field: "notifications", value: memberNotifications }));
    }

    if (memberAutoReports !== undefined) {
      dispatch(memberActions.setValue({ field: "autoReports", value: memberAutoReports }));
    }
  }, [
    allLocationsList,
    allMembersList,
    allOrganizations,
    currentMemberRole,
    currentOrganizationLocations,
    currentOrganizationMembers,
    dispatch,
    locations,
    members,
    currentMemberOrganizationID,
    memberNotifications,
    getCurrentMember,
    memberAutoReports,
    currentLocations,
    currentServiceLocations.length,
  ]);

  return <div>{newLocationID !== 0 ? <DashboardPoller LocationID={newLocationID} setNewLocationID={() => setNewLocationID(0)} /> : ""}</div>;
};

export default DataPoller;
